import icon1 from "../assets/img/numbers/icon-1.png";
import icon2 from "../assets/img/numbers/icon-2.png";
import icon3 from "../assets/img/numbers/icon-3.png";
import icon4 from "../assets/img/numbers/icon-4.png";
import icon5 from "../assets/img/numbers/icon-5.png";
import icon6 from "../assets/img/numbers/icon-6.png";

export const dataNumbers = {
  title: "Мы именно те, кого вы искали",
  items: [
    {
      icon: icon1,
      title: "10+ проектов и кейс EdTech №1 в AppStore",
      description:
        "Наш опыт позволит сделать приложение быстрым и приятным для пользователя",
    },
    {
      icon: icon2,
      title: "Сэкономим тысячи $ и месяцы на запуске",
      description:
        "Лайфхаки в тестировании и разработке при помощи метода умной разработки",
    },
    {
      icon: icon3,
      title: "Показываем каждый этап разработки",
      description:
        "Делаем работу прозрачной, чтобы вы были спокойны и довольны все время",
    },
    {
      icon: icon4,
      title: "Обучили 100+ разработчиков",
      description:
        "Наши сотрудники преподают в ведущей школе разработки приложений в Европе - Zerocoder",
    },
    {
      icon: icon5,
      title: "30 дней на приемку приложения",
      description:
        "Обычно, студии разработки дают 1 или 2 недели. Разница очевидна",
    },
    {
      icon: icon6,
      title: "300к пользователей? Элементарно!",
      description: "Наше приложение уже прекрасно справилось с такой нагрузкой",
    },
  ],
};
