import img1 from "../assets/img/reviews/img-1.jpg";
import img2 from "../assets/img/reviews/img-2.jpg";
// import img3 from '../assets/img/reviews/img-3.jpg';
import img4 from "../assets/img/reviews/img-4.jpg";

export const dataReviews = {
  title: "Отзывы клиентов",
  items: [
    {
      text: "Благодарим команду за профессиональную работу. Simple Inventions подходят ко всему ответственно, выполняют задачи в дедлайн и помогают совершенствовать приложение AB.MONEY, благодаря чему оно выходило на первые строчки App Store в рейтинге «Образование». Команда знает своё дело и работа с ними проходит легко и продуктивно.",
      image: img1,
      name: "Валерия Суворова",
      position: "Product manager, ",
      organisation: "AB.MONEY",
    },
    {
      text: "Спасибо за вашу работу! Очень долго искала до вас адекватную команду разработчиков по приложению, только потратила время и деньги. На звонке с вами сразу поняла, что вы – профессионалы и знаете, что делаете. Уже третье приложение создаем с вами, однозначно могу вас рекомендовать (и даже рекомендую знакомым)😊",
      image: img2,
      name: "Мария Сагалай",
      position: "Генеральный директор, ",
      organisation: "Cake School",
    },
    {
      text: "Мы от всего сердца благодарим компанию Simple Inventions и настоятельно рекомендуем обращаться к ним за созданием сайтов и приложений. Гарантирую, что вы получите только удовольствие от работы с ними. Более того, у нас в планах есть еще несколько этапов развития нашего бизнеса и без участия ребят это не представляется возможным. Так что мы ждем с нетерпением продолжения работы и реализации крутых идей.",
      image: img4,
      name: "Максим и Елена Руваевы",
      position: "Основатели и владельцы бизнеса, ",
      organisation: "Mobile App",
    },
  ],
};
