import icon1 from "../assets/img/promo/icon-1.png";
import icon2 from "../assets/img/promo/icon-2.png";
import icon3 from "../assets/img/promo/icon-3.png";
import img from "../assets/img/promo/phones.png";

export const dataPromo = {
  title: "Разработка прибыльных приложений под ключ",
  image: img,
  items: [
    {
      icon: icon1,
      description: "Сэкономим тысячи $ и месяцы на запуске",
    },
    {
      icon: icon2,
      description: "10+ проектов успешно запущено",
    },
    {
      icon: icon3,
      description: "Кейсы приложений в категориях MedTech и EdTech",
    },
  ],
};
