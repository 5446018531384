import img1 from '../assets/img/tasks/image-1.svg';
import img2 from '../assets/img/tasks/image-2.svg';
import img3 from '../assets/img/tasks/image-3.svg';
import img4 from '../assets/img/tasks/image-4.svg';

export const dataTasks = {
  title: 'Задачи, в которых мы эксперты',
  items: [
    {
      title: 'Быстро разработать приложение под ключ',
      description:
        'Расскажем про узкие места, оптимизируем функционал, составим ТЗ и реализуем приложение',
      image: img1,
    },
    {
      title: 'Автоматизировать бизнес процессы',
      description:
        'Зарабатывать станет быстрее и проще за счет минимизации ручной работы и ошибок из-за человеческого фактора',
      image: img2,
    },
    {
      title: 'Быстро сгенерировать и проверить гипотезы',
      description:
        'Скорость проверки - критически важный фактор кратного роста бизнеса. Мы в скорости имеем наибольшую экспертизу',
      image: img3,
    },
    {
      title: 'Усилить монетизацию и LTV в медиа проекте',
      description:
        'Много рекламы и инфо продукты провоцируют падение охватов. Приложение - это стабильная прибыль без оттока аудитории',
      image: img4,
    },
  ],
};
