import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { Header } from "../components/layout";
import { dataButton } from "../data/dataButton";
import { dataNavbar } from "../data/dataNavbar";

const AppLayout = () => {
  const location = useLocation();

  useEffect(() => {}, [location]);

  //

  return (
    <div className="layout-app">
      {/* <ScrollToTop top={500} />*/}
      {/*   <CookiePolicy />*/}
      <Header dataNavbar={dataNavbar} dataButton={dataButton} />
      <div className="layout-page">
        <Outlet />
      </div>
      {/*<Footer data={dataFooter} />*/}
    </div>
  );
};

export default AppLayout;
