// Components
import Cases from "../components/layout/cases/Cases";
import Contacts from "../components/layout/contacts/Contacts";
import Faq from "../components/layout/faq/Faq";
import Numbers from "../components/layout/numbers/Numbers";
import Plan from "../components/layout/plan/Plan";
import Promo from "../components/layout/promo/Promo";
import Reviews from "../components/layout/reviews/Reviews";
import Structure from "../components/layout/structure/Structure";
import Tasks from "../components/layout/tasks/Tasks";

// Data
import { dataButton } from "../data/dataButton";
import { dataCases } from "../data/dataCases";
import { dataContacts } from "../data/dataContacts";
import { dataFaq } from "../data/dataFaq";
import { dataNumbers } from "../data/dataNumbers";
import { dataPlan } from "../data/dataPlan";
import { dataPromo } from "../data/dataPromo";
import { dataReviews } from "../data/dataReviews";
import { dataStructure } from "../data/dataStructure";
import { dataTasks } from "../data/dataTasks";

const HomePage = () => {
  return (
    <>
      {/*<Header dataNavbar={dataNavbar} dataButton={dataButton} />*/}
      <Promo dataPromo={dataPromo} dataButton={dataButton} />
      <Tasks dataTasks={dataTasks} />
      <Cases dataCases={dataCases} dataButton={dataButton} />
      <Reviews dataReviews={dataReviews} />
      <Numbers dataNumbers={dataNumbers} />
      <Structure dataStructure={dataStructure} />
      <Plan dataPlan={dataPlan} dataButton={dataButton} />
      <Faq dataFaq={dataFaq} />
      <Contacts dataContacts={dataContacts} />
    </>
  );
};

export default HomePage;
