import img1 from '../assets/img/structure/image-1.svg';
import img2 from '../assets/img/structure/image-2.svg';
import img3 from '../assets/img/structure/image-3.svg';
import img4 from '../assets/img/structure/image-4.svg';

export const dataStructure = {
  title: 'Структура работы',
  items: [
    {
      title: 'Знакомство через бизнес-аналитику',
      list: [
        'Сбор данных по проекту и цели на созвоне',
        'Обратная связь по снижению затрат и узким сторонам проекта',
        'Оценка стоимости и времени на разработку',
      ],
      image: img1,
    },
    {
      title: 'Определение архитектуры и функций',
      list: [
        'Шлифовка идеи исходя из анализа конкурентов и нашей экспертизы в запуске 50+ проектов',
        'Создание ТЗ и разделение проекта на спринты, чтобы работа над проектом была прозрачной',
      ],
      image: img2,
    },
    {
      title: 'Дизайн, влюбляющий пользователей',
      list: [
        'Детальный анализ дизайна конкурентов, создание базы лучших практик по вашей нише',
        'Дизайн концепции и аргументация, почему именно такой дизайн победит конкурентов',
        'Дизайн вашего приложения, которое пройдет модерацию магазинов и не создаст проблем в разработке',
      ],
      image: img3,
    },
    {
      title: 'Разработка и запуск приложения',
      list: [
        'Разработка архитектуры, чтобы приложение было быстрым и выдержало 600 000 пользователей',
        'Разработка приложения спринтами, чтобы вы могли видеть развитие и были спокойны',
        'Публикация в AppStore и GooglePlay',
      ],
      image: img4,
    },
  ],
};
