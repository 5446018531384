import img from "../assets/img/contacts/qr.png";

export const dataContacts = {
  title: "Контакты",
  image: img,
  text: "Отсканируйте QR, чтобы получить презентацию и кейсы в Телеграм",
  items: [
    { title: "Privacy", subtitle: "Соглашение", link: "О персональных данных" },
    {
      title: "Office",
      subtitle: "Москва, 123100, Пресненская наб. 12, Город Столиц",
      link: "+7(925)995-87-90",
    },
    {
      title: "Email",
      subtitle: "Пообщаться:",
      link: "info@simpleinventions.tech",
    },
  ],
};
